import { HeaderV1 } from "./HeaderV1";
import FooterV1 from "./footer_v1/FooterV1";
import img1 from "./../../assets/images/v1_1.png";
import img2 from "./../../assets/images/v1_2.png";
import ex1 from "./../../assets/images/ex1_2night_3day_36hole.png";
import ex2 from "./../../assets/images/ex2_2night_3day_54hole.png";
import ex3 from "./../../assets/images/ex3_3night_4day_54hole.png";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../../components/Accordion/Accordion";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function HomePageV1() {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center">
      <HeaderV1 />
      <div className="flex-1 h-full w-full flex flex-col items-center overflow-y-auto">
        <img src={img1} alt="" width={"100%"} />
        <img src={img2} alt="" width={"100%"} />
        <PlanExampleToggle imgSrc={ex1} title={"2박3일(36홀) 일정 예시"} />
        <PlanExampleToggle imgSrc={ex2} title={"2박3일(54홀) 일정 예시"} />
        <PlanExampleToggle imgSrc={ex3} title={"3박4일(54홀) 일정 예시"} />
        <FooterV1 />
      </div>
    </div>
  );
}

function PlanExampleToggle({
  imgSrc,
  title,
}: {
  imgSrc: string;
  title: string;
}) {
  return (
    <Accordion disableGutters={true} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls="panel1d-BetRule"
        // id="panel1d-BetRuleHeder"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <img src={imgSrc} alt="" />
      </AccordionDetails>
    </Accordion>
  );
}
