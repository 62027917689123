import kakaoIcon from "./../../../assets/images/icon-kakao.png";
import callIcon from "./../../../assets/images/icon-call.png";

const FooterV1 = () => {
  return (
    <footer className="w-full flex flex-col px-[20px] py-[10px] mt-2 bg-gray-300">
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-start gap-2 mb-2">
          <img
            src="/images/special_logo.png"
            alt="special tour"
            style={{ width: "50px", height: "50px" }}
          />
          <div>
            <div className="text-[18px]">스페셜 투어</div>
            <div className="text-[15px]">SPECIAL TOUR (골프전문)</div>
          </div>
          <div className="flex-1"></div>
          <a href={"https://pf.kakao.com/_EPIxfG"}>
            <img
              className="w-[40px] rounded-full shadow-lg hover:opacity-80 mr-2"
              src={kakaoIcon}
              alt=""
            />
          </a>
          <a href={"tel:010-3131-2671"}>
            <img
              className="w-[40px] rounded-full shadow-lg hover:opacity-80"
              src={callIcon}
              alt=""
            />
          </a>
        </div>
        <div className="text-[16px] italic mb-2">
          골프는 스포츠가 아니라 인생입니다
        </div>
        <div>대표자: 김정환</div>
        <div>연락처: 010-3131-2671</div>
        <div>E-mail: kkh960313@naver.com</div>
        <div className="text-[13px] flex flex-row mt-2">
          <div className="w-[50%] flex flex-col">
            <p className="text-[12px] font-600 italic mb-1">주소(한국) </p>
            <p>경북 경주시 안강읍</p>
            <p>비화원로 22. 103호</p>
            <p>Fax. 054-761-4717</p>
          </div>
          <div className="w-[50%] flex flex-col">
            <p className="text-[13px] font-600 italic mb-1">주소(일본) </p>
            <p>효고현 다카라즈카시</p>
            <p>사카에초 2-2-2</p>
            <p>T. 079-787-1771</p>
          </div>
        </div>
      </div>
      <p className="mt-2 text-[14px] font-800 text-center">
        © 2023 Special Tour. All rights reserved
      </p>
    </footer>
  );
};

export default FooterV1;
