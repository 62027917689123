import React, { ReactNode, useState } from "react";
import { HomePageV1 } from "./page/v1/HomePageV1";

function App() {
  return (
    <div className="mx-auto max-w-[500px] h-screen flex flex-col items-center">
      {/* <div className="w-screen h-screen flex flex-col items-center"> */}
      <HomePageV1 />
    </div>
  );
}

export default App;
